import React, { useState, useEffect } from 'react';
import  commerce  from './lib/commerce';
import './App.css';
import 
{ Products, Navbar, Cart, Checkout, DiningTables, Customorders, About, Footer, Frames, Bowls, RusticBowls, ClassicBowls, Balls, RootBalls, RolledBalls, Basins, Breadboards, Chairs, Benches, Shelves, Sidetables, Coffeetables, Stumps } 
from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrderComplete from './components/CheckoutForm/OrderComplete';
import ReactPixel from 'react-facebook-pixel';

const App = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({}); 
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init(process.env.REACT_PUBLIC_FACEBOOK_PIXEL, options);

ReactPixel.pageView(); // For tracking page view
ReactPixel.track(); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
ReactPixel.trackSingle('700112977753791'); // For tracking default events.

 const fetchCart = async () => {
   setCart(await commerce.cart.retrieve());

 }


  const fetchProducts = () => {
    commerce.products.list().then((products) => {
      setProducts(products.data);
    }).catch((error) => {
      console.log('There was an error fetching the products', error)
    });
  }

  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

      setOrder(incomingOrder);

      refreshCart();
    } catch (error) {
      setErrorMessage(error.data.error.message);
    }
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();

    setCart(newCart);
  };


  const handleAddToCart = async (productId, quantity) => {
    const item = await commerce.cart.add(productId, quantity);

    setCart(item.cart);
  };

  useEffect(() => {
    fetchProducts();
    fetchCart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleUpdateCartQty = async (lineItemId, quantity) => {
    const response = await commerce.cart.update(lineItemId, { quantity });

    setCart(response.cart);
  };

  const handleRemoveFromCart = async (lineItemId) => {
    const response = await commerce.cart.remove(lineItemId);

    setCart(response.cart);
  };

  const handleEmptyCart = async () => {
    const response = await commerce.cart.empty();

    setCart(response.cart);
  };

  return (
    <Router>
    <div className="app">
    <Navbar totalItems={cart.total_items} />
    <Routes>
      <Route 
      exact path='/' element={<Products products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/cart' element={<Cart cart={cart}
      handleUpdateCartQty={handleUpdateCartQty}
      handleRemoveFromCart={handleRemoveFromCart}
      handleEmptyCart={handleEmptyCart}
      />} />
      <Route path='/checkout' element={<Checkout cart={cart} order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage}/>} />
      <Route 
      exact path='/diningtables' element={<DiningTables products={products} onAddToCart={handleAddToCart}/>} />
       <Route 
      exact path='/frames' element={<Frames products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/bowls' element={<Bowls products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/rusticbowls' element={<RusticBowls products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/classicbowls' element={<ClassicBowls products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/balls' element={<Balls products={products} onAddToCart={handleAddToCart}/>} /><Route 
      exact path='/rootballs' element={<RootBalls products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/rolledballs' element={<RolledBalls products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/basins' element={<Basins products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/breadboards' element={<Breadboards products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/chairs' element={<Chairs  products={products} onAddToCart={handleAddToCart}/>} />
      <Route 
      exact path='/benches' element={<Benches products={products} onAddToCart={handleAddToCart}/>} />
       <Route 
      exact path='/shelves' element={<Shelves products={products} onAddToCart={handleAddToCart}/>} />
       <Route 
      exact path='/sidetables' element={<Sidetables products={products} onAddToCart={handleAddToCart}/>} />
       <Route 
      exact path='/coffeetables' element={<Coffeetables products={products} onAddToCart={handleAddToCart}/>} />
       <Route path='/ordercomplete' element={<OrderComplete cart={cart} />} />
        <Route 
      exact path='/stumps' element={<Stumps products={products} onAddToCart={handleAddToCart}/>} />
      <Route path='/customorders' element={<Customorders />} />
      <Route path='/customorders' element={<Customorders />} />
      <Route path='/about' element={<About/>} />
    </Routes>
    </div>
    <Footer />
    </Router>
  );
}

export default App;
