import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import './Sidenav.css';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import CountertopsIcon from '@mui/icons-material/Countertops';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import WeekendIcon from '@mui/icons-material/Weekend';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import TableBarIcon from '@mui/icons-material/TableBar';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import HexagonIcon from '@mui/icons-material/Hexagon';


export default function Shop() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
       <List className='sidenav'>
      <h2>Decor</h2>
       <Link to='/bowls' className='sidenav__link'>
       <RiceBowlIcon className="sidenav__icon" />
        <span>Bowls</span>
       </Link>
       <Link to='/rusticbowls' className='sidenav__link'>
        <span style={{marginLeft:'15px', marginTop:'-10px', fontSize:'90%'}}> Rustic Bowls</span>
       </Link>
       <Link to='/classicbowls' className='sidenav__link'>
        <span style={{marginLeft:'15px', marginTop:'-10px', fontSize:'90%'}}> Classic Bowls</span>
       </Link>
       <Link to='/frames' className='sidenav__link'>
       <CropPortraitIcon className="sidenav__icon" />
        <span>Frames</span>
       </Link>
       <Link to='/balls' className='sidenav__link'>
       <SportsBaseballIcon className="sidenav__icon"/>
        <span>Balls</span>
       </Link>
       <Link to='/rootballs' className='sidenav__link'>
        <span style={{marginLeft:'15px', marginTop:'-10px', fontSize:'90%'}}> Root Balls</span>
       </Link>
       <Link to='/rolledballs' className='sidenav__link'>
        <span style={{marginLeft:'15px', marginTop:'-10px', fontSize:'90%'}}> Rolled Balls</span>
       </Link>
       <Link to='/stumps' className='sidenav__link'>
       <HexagonIcon className="sidenav__icon"/>
        <span>Stumps</span>
       </Link>
      </List>
      <Divider />

      <List className='sidenav'>
      <h2>Kitchen & Bathroom</h2>
       <Link to='/basins' className='sidenav__link'>
       <CountertopsIcon className='sidenav__icon'/>
        <span>Basins</span>
       </Link>
       <Link to='/breadboards' className='sidenav__link'>
       <BreakfastDiningIcon className='sidenav__icon'/>
        <span>Bread boards</span>
       </Link>
      </List>
      <Divider />

      <List className='sidenav'>
      <h2>Seating</h2>
       <Link to='/benches' className='sidenav__link'>
       <WeekendIcon className='sidenav__icon'/>
        <span>Benches</span>
       </Link>
       <Link to='/chairs' className='sidenav__link'>
       <ChairAltIcon className='sidenav__icon'/>
        <span>Chairs & Stools</span>
       </Link>
      </List>
      <Divider />
      
      <List className='sidenav'>
      <h2>Living Areas</h2>
       <Link to='/shelves' className='sidenav__link'>
       <BorderInnerIcon className='sidenav__icon'/>
        <span>Shelving</span>
       </Link>
       <Link to='/sidetables' className='sidenav__link'>
       <TableBarIcon className='sidenav__icon'/>
        <span>Sidetables</span>
       </Link>
       
       <Link to='coffeetables' className='sidenav__link'>
       <TableBarIcon className='sidenav__icon'/>
        <span>Coffeetables</span>
       </Link>

       <Link to='/diningtables' className='sidenav__link'>
       <TableRestaurantIcon className='sidenav__icon'/>
        <span>Diningtables</span>
       </Link>
      </List>
    </Box>
  );

  return (
    <div>
      {['Shop by category', ].map((anchor) => (
        <React.Fragment key={anchor}>
        <Button onClick={toggleDrawer(anchor, true)}
            className='shop__menu'>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
