import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton } 
from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';

import useStyles from './styles';

const Product = ({ product, onAddToCart }) => {
  const classes = useStyles();
  

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} title={product.name} > 
      <img className={classes.media} src={product.image?.url} alt={product.name} />
      </CardMedia>
      <CardContent>
        <div className={classes.cardContent}>
          <Typography gutterBottom variant="h4" component="h2">
            {product.name}
          </Typography>
        </div>
        <Typography gutterBottom variant="body2" component="p">
            In Stock:{product.inventory.available}
          </Typography>
          {/* {product.inventory.available === 0 then do not display product} */}
        <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant="body2" color="textSecondary" component="p" />
      <Typography gutterBottom variant="h5" component="h2" style={{marginLeft:'5px'}}>
            R{product.price.formatted}
          </Typography>
      </CardContent>
      {product.inventory.available > 0 ? <CardActions disableSpacing className={classes.cardActions}>
        <IconButton aria-label="Add to Cart" onClick={() => onAddToCart(product.id, 1)} style={{color:'darkOrange'}}>
          <AddShoppingCart />
        </IconButton>
      </CardActions> : <div />}
      
    </Card>
  );
};

export default Product;

