import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  height: '70%',
  overflowY: 'auto'
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{color:'black', fontSize:'10px', fontWeight:'400'}}>T's & C's</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms of Use
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Introduction
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          1.1. This website can be accessed at www.forestcreations.co.za related Mobi-sites and software applications (the “Website”) and is owned and operated by Forest Creations (PTY) Ltd. (“Forest Creations,” “we,” “us” and “our”).
          <br/>
          1.2. These Website Terms and Conditions (“Terms and Conditions”) govern the ordering, sale and delivery of Goods, and the use of the Website.
          <br/>
          1.3. These Terms and Conditions are binding and enforceable against every person that accesses or uses this Website (“you,” “your” or “user”), including without limitation each user who registers as contemplated below (“registered user”). By using the Website and by clicking on the “Register Now” button on the Website, as may be applicable, you acknowledge that you have read and agree to be bound by these Terms and Conditions.
          <br/>
          1.4. The Website enables you to shop online for an extensive range of wooden furniture items, including but not limited to “Stools and Chairs” “Tables” “Bowls” “Decor” “Shelves” (“Goods”).
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Important Notice
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          2.1. These Terms and Conditions apply to users who are consumers for purposes of the Consumer Protection Act, 68 of 2008 (the “CPA”).
            <br/>
            2.2. These Terms and Conditions contain provisions that appear in similar text and style to this clause and which -
            <br/>
            2.2.1. may limit the risk or liability of Forest Creations or a third party; and/or
            <br/>
            2.2.2. may create risk or liability for the user; and/or
            <br/>
            2.2.3. may compel the user to indemnify Forest Creations or a third party; and/or
            <br/>
            2.2.4. serves as an acknowledgement, by the user, of a fact.
            <br/>
            2.3. Your attention is drawn to these Terms and Conditions because they are important and should be carefully noted.
            <br/>
            2.4. If there is any provision in these Terms and Conditions that you do not understand, it is your responsibility to ask Forest Creations to explain it to you before you accept the Terms and Conditions or continue using the Website.
            <br/>
            2.5. Nothing in these Terms and Conditions is intended or must be understood to unlawfully restrict, limit, or avoid any right or obligation created for either you or Forest Creations in terms of the CPA.
            <br/>
            2.6. Forest Creations permits the use of this Website subject to the Terms and Conditions. By using this Website in any way, you shall be deemed to have accepted all the Terms and Conditions unconditionally. You must not use this Website if you do not agree to the Terms and Conditions.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Returns
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          3.1. Please refer to our Returns Policy for more information about returning products (and related refunds, replacements, or repairs). The Returns Policy is incorporated by reference (which means that it forms part of these Terms and Conditions).
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Registration and use of the website
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          4.1. Only registered users may order Goods on the Website.
            <br/>
            4.2. To register as a user, you must provide a unique username and password and provide certain information and personal details to Forest Creations. You will need to use your unique username and password to access the Website to purchase Goods.
            <br/>
            4.3. You agree and warrant that your username and password shall:
            <br/>
            4.3.1. be used for personal use only; and
            <br/>
            4.3.2. not be disclosed by you to any third party.
            <br/>
            4.4. For security purposes you agree to enter the correct username and password whenever ordering Goods, failing which you will be denied access.
            <br/>
            4.5. You agree that, once the correct username and password relating to your account have been entered, irrespective of whether the use of the username and password is unauthorised or fraudulent, you will be liable for payment of such order, save where the order is cancelled by you in accordance with these Terms and Conditions.
            <br/>
            4.6. You agree to notify Forest Creations immediately upon becoming aware of or suspecting any unauthorised access to or use of your username and password and to take steps to mitigate any resultant loss or harm.
            <br/>
            4.7. By using the Website, you warrant that you are 18 (eighteen) years of age or older and of full legal capacity. If you are under the age of 18 (eighteen) or if you are not legally permitted to enter into a binding agreement, then you may use the Website only with the involvement and supervision of your parent or legal guardian. If your parent or legal guardian supervises you and gives his/her consent, then such person agrees to be bound to these Terms and Conditions and to be liable and responsible for you and all your obligations under these Terms and Conditions.
            <br/>
            4.8. You agree that you will not in any way use any device, software, or other instrument to interfere or attempt to interfere with the proper working of the Website. In addition, you agree that you will not in any way use any robot, spider, other automatic device, or manual process to monitor, copy, distribute or modify the Website or the information contained herein, without the prior written consent from an authorised Forest Creations representative (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this Website).
            <br/>
            4.9. You may not use the Website to distribute material which is defamatory, offensive, contains or amounts to hate speech or is otherwise unlawful.
            <br/>
            4.10. You may not in any way display, publish, copy, print, post or otherwise use the Website and/or the information contained therein without the express prior written consent of an authorised Forest Creations representative.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Conclusion of sales and availability of stock
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          5.1. Registered users may place orders for Goods, which Forest Creations may accept or reject. Whether or not Forest Creations accepts an order depends on the availability of Goods, correctness of the information relating to the Goods (including without limitation the price) and receipt of payment or payment authorisation by Forest Creations for the Goods.
          <br/>
          5.2. NOTE: Forest Creations will indicate the acceptance of your order by delivering the Goods to you or allowing you to collect them, and only at that point will an agreement of sale between you and Forest Creations come into effect (the “Sale”). This is regardless of any communication from Forest Creations stating that your order or payment has been confirmed. Forest Creations will indicate the rejection of your order (by Forest Creations itself) by cancelling it and, as soon as possible thereafter, refunding you for any amount already paid.
          <br/>
          5.3. Prior to delivery or your collection of the Goods, you may cancel an order at any time provided you do so before receiving a dispatch or delivery notice. After delivery or your collection of the Goods, you may return the Goods only in accordance with the Returns Policy.
          <br/>
          5.4. Placing Goods in a wishlist or shopping basket without completing the purchase cycle does not constitute an order for such Goods, and as such, goods may be removed from the shopping basket if stock is no longer available, or the price thereof might change without notice to you. You cannot hold Forest Creations liable if such Goods are not available or are not available at the price when you complete or attempt to complete the purchase cycle at a later stage.
          <br/>
          5.5. You acknowledge that stock of all Goods on offer is limited, and that pricing may change at any time without notice to you. In the case of Goods for sale by Forest Creations, Forest Creations will take all reasonable efforts to monitor stock levels and ensure that when stock is no longer available, that offers thereof are discontinued on the Website. However, we cannot guarantee the availability of stock. When Goods are no longer available after you have placed an order, Forest Creations will notify you and you will be entitled to a refund of any amount already paid by you for such Goods.
          <br/>
          5.6. Certain Goods may not be purchased for re-sale. Should we suspect that any such Goods are being purchased for sale, we are entitled to cancel your order immediately on notice to you.
          <br/>
          5.7. Please see details relating to Pre-orders in our FAQ’s: Pre-Order, which are incorporated by reference.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Payment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          6.1. We are committed to providing secure online payment facilities. All transactions are encrypted using appropriate encryption technology.
            <br/>
            6.2. Payment may be made for Goods via the following methods (depending on its availability and/or your eligibility to use such a method) -
            <br/>
            6.2.1. debit card: where payment is made by debit card, we may require additional information to authorise and/or verify the validity of payment. In such cases we are entitled to withhold delivery until such time as the additional information is received by us and authorisation is obtained by us for the amounts. If we do not receive authorisation your order for the Goods will be cancelled. You warrant that you are fully authorised to use the debit card supplied for purposes of paying the Goods. You also warrant that your debit card has sufficient available funds to cover all the costs incurred because of the services used on the Website.
            <br/>
            6.2.2. credit card: where payment is made by credit card, we may require additional information to authorise and/or verify the validity of payment. In such cases we are entitled to withhold delivery until such time as the additional information is received by us and authorisation is obtained by us for the amounts. If we do not receive authorisation your order for the Goods will be cancelled. You warrant that you are fully authorised to use the credit card supplied for purposes of paying the Goods. You also warrant that your credit card has sufficient available funds to cover all the costs incurred because of the services used on the Website.
            <br/>
            6.2.3. direct bank deposit or electronic funds transfer: if you pay via direct bank deposit or electronic funds transfer, payment must be made within 5 (five) days of placing your order. Forest Creations will not accept your order if payment has not been received.
            <br/>
            6.2.4. Instant EFT.
            <br/>
            6.3. The above payment options are explained in more detail in our Frequently Asked Questions (“FAQ”): Payment, which are incorporated by reference.
            <br/>
            6.4. You may contact us via shop@forestcreations.co.za to obtain a full record of your payment. We will also send you email communications about your order and payment.
            <br/>
            6.5. Once you have selected your payment method (save for cash on delivery or direct bank deposit), you will be directed to a link to a secure site for payment of the applicable purchase price for the Goods.
          </Typography>
          
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Delivery of goods
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          7.1. Forest Creations offers 2 (two) methods of delivery of Goods to you. You may elect delivery via:
            <br/>
            7.1.1. courier; or
            <br/>
            7.1.2. self-collection. 7.2. For more information about delivery, please see our FAQs: Shipping and Delivery, which are incorporated into these Terms by reference. Our delivery charges are subject to change at any time, without prior notice to you, so please check the FAQs for the most up-to-date information. You will see the applicable delivery charges in your cart when you check out.
            <br/>
            7.3. Where it accepts your order, Forest Creations will deliver the Goods to you as soon as reasonably possible, but no later than 30 (thirty) days of receipt of your payment (“Delivery Period”). We will notify you if we are unable to deliver the Goods during the Delivery Period. You may then, within 7 (seven) days of receiving such notification elect whether to cancel your order for the Goods. If you elect to cancel your order, we will reimburse you for the purchase price.
            <br/>
            7.4. Forest Creations obligation to deliver a product to you is fulfilled when we deliver the product to the physical address nominated by you for delivery of the order. Forest Creations is not responsible for any loss or unauthorised use of a product, after it has delivered the product to the physical address nominated by you.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Errors
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          8.1. We shall take all reasonable efforts to accurately reflect the description, availability, purchase price and delivery charges of Goods on the Website. However, should there be any errors of whatsoever nature on the Website (which are not due to our gross negligence), we shall not be liable for any loss, claim or expense relating to a
          <br/>
          transaction based on any error, save – in the case of any incorrect purchase price – to the extent of refunding you for any amount already paid, or otherwise as set out in the Returns Policy.
          <br/>
          8.2. Forest Creations shall not be bound by any incorrect information regarding our Goods displayed on any third-party websites.
          </Typography>
          
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Gift Vouchers & Coupons
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          9.1. Forest Creations may from time to time make physical or electronic gift vouchers (“Gift Vouchers”) and promotional coupons or discounts (“Coupons”) available for use on the Website towards the purchase of Forest Creations Goods. Gift Vouchers and Coupons can only be redeemed while they are valid, and their expiry dates cannot be extended. More specifically:
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Gift Vouchers
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          9.2.1. Gift Vouchers that are purchased by registered users are valid for 3 years after Sale. Gift Vouchers that Forest Creations gives away for free are valid for the period stated thereon. In each case, if your Voucher has not been used within that period, it will expire.
          <br/>
          9.2.2. Gift Vouchers cannot be used to buy other Gift Vouchers or Coupons. They do not accrue interest and are not refundable for cash once purchased or otherwise obtained. If your Gift Voucher value is less than the amount required to cover the full order you wish to place, you may make up the difference by paying via one of our other payment methods.
          <br/>
          9.2.3. Forest Creations is not responsible for any harm due to the loss, unauthorised use, or unauthorised distribution of a Gift Voucher, after it has delivered the Gift Voucher to you, or the email address nominated by you.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Coupons
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          9.3.1. There are two types of Coupons: A Coupon with a fixed amount of a discount, e.g., R100 off (“Fixed Coupon “), and a Coupon with a percentage discount, e.g., 10% off (“Percentage Coupon “).
          <br/>
          9.3.2. Coupons are issued in Forest Creations’ sole discretion, and we are entitled at any time to correct, cancel, or reject a Coupon for any reason (including without limitation where a Coupon has been distributed in an unauthorised manner). Users do not have a right to Coupons, and Coupons cannot be earned. Coupons are issued under specific terms and conditions regulating when and how they may be used.
          <br/>
          9.3.3. As a general rule, and unless specified otherwise on the specific Coupon itself:
          <br/>
          9.3.3.1. each Coupon can only be used once.
          <br/>
          9.3.3.2. only one Coupon can be used per order.
          <br/>
          9.3.3.3. only one Coupon can be used on the Website per person per promotion/campaign.
          <br/>
          9.3.3.4. Percentage Coupons may only be redeemed on purchases with a total cart value of less than R5,000.
          <br/>
          9.3.3.5. where a Percentage Coupon has been used and you wish to cancel any items in the order prior to making payment, the entire order must be cancelled. You will be issued with a new Percentage Coupon and will need to place the order again, without the item that you wished to cancel.
          <br/>
          9.3.3.6. a Coupon must be used at check-out – it cannot be used later existing orders; and
          <br/>
          9.3.3.7. the value of the Coupon will be set off against the value of your shopping basket and the balance remaining, if any, will be payable by you.
          <br/>
          9.3.4. Coupons cannot be used to buy Gift Vouchers or other Coupons and cannot be exchanged or refunded for cash or credit. Forest Creations is not responsible for any harm due to the loss, unauthorised use, or distribution of a Coupon.
          <br/>
          9.3.5. If for any reason a Coupon does not reflect on the final amount due from you at check-out, you can contact us here to confirm if the Coupon is still valid. If Forest Creations confirms that the Coupon is still valid and you have already placed your order, you can choose whether to cancel the order and place it again with the Coupon, or you can use the Coupon on your next order within the limitations of the specific Coupon’s terms and conditions.
          <br/>
          9.3.6. You may be required to submit the original communication containing the Coupon code, and any other information requested by Forest Creations, before you are able to use a Coupon.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Daily Deals and other discounted Goods
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          10.1. From time to time, we may offer certain Goods at discounted prices as part of a Daily Deal, App Only Deal, Bundle Deal, or any other temporary deal which are explained below (each a “Deal”). These will be subject to certain conditions (as set out in these Terms and/or the Website), which define the scope of the Deal. If you buy a product within the scope of a Deal, you will pay the discounted price for that product (the “Deal Price”).
          <br/>
          10.2. However, if you buy a product in a manner that falls outside of the scope of a Deal, then you will pay the then current (non-Deal) selling price on the Website (the “Normal Price”), for each product that falls outside the scope of the Deal.
          <br/>
          10.3. For example: if you buy more than one product in a Daily Deal, you will pay the Deal Price for the first product, but the Normal Price for all products thereafter. Alternatively, if you buy a product in combination with any other products that together do not constitute a Bundle Deal, you will pay the Normal Price for all such products falling outside the scope of the relevant Deal.
          <br/>
          10.4. Daily Deals
          <br/>
          10.4.1. At our discretion, Daily Deals (“Deals”) are available daily from 7am – 23:59pm on weekdays and from 9am – 23:59pm on weekends. In addition, please note that Daily Deals have a stock limit and may expire earlier if stock runs out.
          <br/>
          10.4.2. Daily Deals quantities are limited and as such, after a Daily Deal is sold out, those Goods may be available on the Website at their normal selling prices (but no longer as a Daily Deal).
          <br/>
          10.4.3. We do not guarantee a specific saving. The extent of the Daily Deal or discount is at the sole discretion of Forest Creations.
          <br/>
          10.4.4. Only one of each Daily Deal may be purchased per customer.
          <br/>
          10.4.5. Adding a Daily Deal to your cart or completing your order for a Daily Deal without paying for it, does not reserve the item for you. Forest Creations must receive payment from you within 24 hours of you placing your order for a Daily Deal, otherwise, we will cancel your order.
          <br/>
          10.4.6. Forest Creations will reserve stock for customers in the order in which it receives payment. Therefore, if you pay via EFT, you might not get your item because Forest Creations may only receive payment from you a few days later, and in the meantime, the Daily Deal might sell out to customers paying immediately.
          <br/>
          10.4.7. By purchasing any Daily Deal, you are also automatically opting in for our Daily Deals daily newsletter as well as our general newsletter (you may opt-out of these newsletters at any time). Opting out of these newsletters after purchase will not affect the value of the Goods purchased.
          <br/>
          10.5. Bundle Deals
          <br/>
          10.5.1. We may from time to time offer bundle deals for sale under the Bundle Deals tab on the Website (“Bundle Deals “). Each Bundle Deal will consist of two or more products that either we or you (as provided on the Website) have combined in a single bundle.
          <br/>
          10.5.2. Any saving or discount resulting from purchasing a Bundle Deal instead of its component products separately may be applied to any of the component products individually in our sole discretion. The actual purchase price (after applying any applicable saving or discount) of each component product will be communicated to you upon checkout and reflected in your order history. This is relevant to the amount that would be refunded to you, if you were to return any product in a Bundle Deal for a refund, in accordance with our Returns Policy.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Privacy Policy
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          11.1. We respect your privacy and will take all reasonable measures to protect it, as more fully detailed in our Privacy Policy, which is incorporated by reference.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Changes to these Terms and Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          12.1. Forest Creations may, in its sole discretion, change any of these Terms and Conditions at any time. It is your responsibility to regularly check these Terms and Conditions and make sure that you are satisfied with the changes. Should you not be satisfied, you must not place any further orders on, or in any other way use, the Website.
          <br/>
          12.2. Any such change will only apply to your use of this Website after the change is displayed on the Website. If you use the Website after such amended Terms and Conditions have been displayed on the Website, you will be deemed to have accepted such changes.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Electronic communications
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          13.1. When you visit the Website or send emails to us, you consent to receive communications from us or any of our divisions, affiliates, or partners electronically in accordance with our privacy policy as set out in clause 15 above.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Ownership and copyright
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          14.1. The contents of the Website, including any material, information, data, software, icons, text, graphics, lay-outs, images, sound clips, advertisements, video clips, trade names, logos, trademarks, designs, and service marks which are displayed on or incorporated in this Website (“Website Content”) are protected by law, including but not limited to copyright and trademark law. The Website Content is the property of Forest Creations, its advertisers and/or sponsors and/or is licensed to Forest Creations.
          <br/>
          14.2. You will not acquire any right, title, or interest in or to the Website or the Website Content.
          <br/>
          14.3. Any use, distribution or reproduction of the Website Content is prohibited unless expressly authorised in terms of these Terms and Conditions or otherwise provided for in law. To obtain permissions for the commercial use of any Website Content contact us via us Help page.
          <br/>
          14.4. Where any of the Website Content has been licensed to Forest Creations or belongs to any third party, your rights of use will also be subject to any terms and conditions which that licensor or third party imposes from time to time, and you agree to comply with such third-party terms and conditions.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Disclaimer
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          15.1. The use of the Website is entirely at your own risk, and you assume full responsibility for any risk or loss resulting from use of the Website or reliance on any information on the Website.
          <br/>
          15.2. Whilst Forest Creations takes reasonable measures to ensure that the content of the Website is accurate and complete, Forest Creations makes no representations or warranties, whether express or implied, as to the quality, timeliness, operation, integrity, availability, or functionality of the Website or as to the accuracy, completeness, or reliability of any information on the Website. If any such representations or warranties are made by Forest Creations’ representatives, Forest Creations shall not be bound thereby.
          <br/>
          15.3. Forest Creations refuses liability for any damage, loss, or expenses, whether direct, indirect, or consequential in nature, arising out of or in connection with your access to or use of the Website and/or any content therein unless otherwise provided by law.
          <br/>
          15.4. Although Goods sold from the Website may, under certain specifically defined circumstances, be under warranty, the Website itself and all information provided on the Website is provided “as is” without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, completeness, or non-infringement, as may be allowed in law.
          <br/>
          15.5. Any views or statements made or expressed on the Website are not necessarily the views of Forest Creations, its directors, employees and/or agents.
          <br/>
          15.6. In addition to the disclaimers contained elsewhere in these Terms and Conditions, Forest Creations also makes no warranty or representation, whether express or implied, that the information or files available on the Website are free of viruses, spyware, malware, trojans, destructive materials or any other data or code
          which is able to corrupt, destroy, compromise, disrupt, disable, harm, jeopardise or otherwise impede in any manner the operation, stability, security functionality or content of your computer system, computer network, hardware or software in any way. You accept all risk associated with the existence of such viruses, destructive materials or any other data or code which is able to corrupt, compromise, jeopardise, disrupt, disable, harm or otherwise impede in any manner the operation or content of a computer system, computer network, any handset or mobile device, or your hardware or software, save where such risks arise due to the gross negligence or wilful misconduct of Forest Creations, its employees, agents or authorised representatives. Forest Creations thus denies all liability for any damage, loss or liability of any nature whatsoever arising out of or in in connection with your access to or use of the Website.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Linking to third party websites
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          16.1. This Website may contain links or references to other websites (“Third Party Websites”) which are outside of our control, including those of advertisers. These Terms and Conditions do not apply to those Third-Party Websites and Forest Creations is not responsible for the practices and/or privacy policies of those Third-Party Websites or the “cookies” that those sites may use.
          <br/>
          16.2. Notwithstanding the fact that the Website may refer to or provide links to Third Party Websites, your use of such Third-Party Websites is entirely at your own risk, and we are not responsible for any loss, expense, claim or damage, whether direct, indirect, or consequential, arising from your use of such Third-Party Websites or your reliance on any information contained thereon.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Limitation of liability
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          17.1. Forest Creations cannot be held liable for any inaccurate information published on the Website and/or any incorrect prices displayed on the Website, save where such liability arises from the gross negligence or wilful misconduct of Forest Creations, its employees, agents, or authorised representatives. You are encouraged to contact us to report any malfunctions or errors by way of email, emails may be sent to shop@forestcreations.co.za
          <br/>
          17.2. FOREST CREATIONS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL LOSS OR DAMAGES WHICH MIGHT ARISE FROM YOUR USE OF, OR RELIANCE UPON, THE WEBSITE OR THE CONTENT CONTAINED ON THE WEBSITE; OR YOUR INABILITY TO USE THE WEBSITE, AND/OR UNLAWFUL ACTIVITY ON THE WEBSITE AND/OR ANY LINKED THIRD-PARTY WEBSITE.
          <br/>
          17.3. YOU HEREBY INDEMNIFY FOREST CREATIONS AGAINST ANY LOSS, CLAIM OR DAMAGE WHICH MAY BE SUFFERED BY YOURSELF OR ANY THIRD PARTY ARISING IN ANY WAY FROM YOUR USE OF THIS WEBSITE AND/OR ANY LINKED THIRD-PARTY WEBSITE.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Availability and termination
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          18.1. We will use reasonable endeavours to maintain the availability of the Website, except during scheduled maintenance periods, and are entitled to discontinue providing the Website or any part thereof with or without notice to you.
          <br/>
          18.2. Forest Creations may in its sole discretion terminate, suspend, and modify this Website, with or without notice to you. You agree that Forest Creations will not be liable to you if it chooses to suspend, modify or terminate this Website other than for processing any orders made by you prior to such time, to the extent possible.
          <br/>
          18.3. If you fail to comply with your obligations under these Terms and Conditions, including any incident involving payment of the price of an order for any Goods, this may (in our sole discretion with or without notice to you) lead to a suspension and/or termination of your access to the Website without any prejudice to any claims for damages or otherwise that we may have against you.
          <br/>
          18.4. Forest Creations is entitled, for purposes of preventing suspected fraud and/or where it suspects that you are abusing the Website and/or have created multiple user profiles to take advantage of a promotion or Coupon intended by Forest Creations to be used once-off by you, to blacklist you on its database (including suspending or terminating your access to the Website), refuse to accept
          <br/>
          or process payment on any order, and/or to cancel any order concluded between you and Forest Creations, in whole or in part, on notice to you. Forest Creations shall only be liable to refund monies already paid by you (see Forest Creations' Returns Policy in this regard) and accepts no other liability which may arise because of such blacklisting and/or refusal to process any order.
          <br/>
          18.5. At any time, you can choose to stop using the Website, with or without notice to Forest Creations.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Governing law and jurisdiction
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          19.1. These Terms and Conditions and our relationship and/or any dispute arising from or in connection with these Terms and Conditions shall be governed and interpreted in accordance with the laws of the Republic of South Africa. Your continued use of the Website will constitute your consent and submission to the jurisdiction of the South African courts regarding all proceedings, transactions, applications, or the like instituted by either party against the other, arising from any of these Terms and Conditions.
          <br/>
          19.2. In the event of any dispute arising between you and Forest Creations, you hereby consent to the non-exclusive jurisdiction of the High Court of the Republic of South Africa (Western Cape Division, Cape Town) notwithstanding that the quantum in the action or proceedings may otherwise fall below the monetary jurisdiction of that court.
          <br/>
          19.3. Nothing in this clause 23 or the Terms and Conditions limits your right to approach any court, tribunal, or forum of competent jurisdiction in terms of the CPA.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Notices
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          20.1. Forest Creations hereby selects 1202 Bloemhof Ave, Wetton, Cape Town, 7780, as its address for the service of all formal notices and legal processes in connection with these Terms and Conditions (“legal address”). Forest Creations may change this address from time to time by updating these Terms and Conditions.
          <br/>
          20.2. You hereby select the delivery address specified with your order as your legal address, but you may change it to any other physical address by giving Forest Creations not less than 7 days’ notice in writing.
          <br/>
          20.3. Notices must be sent either by hand, prepaid registered post, telefax or email and must be in English. All notices sent -
          <br/>
          20.3.1. by hand will be deemed to have been received on the date of delivery.
          <br/>
          20.3.2. by prepaid registered post, will be deemed to have been received 10 days after the date of posting.
          <br/>
          20.3.3. by telefax before 16h30 on a business day will be deemed to have been received, on the date of successful transmission of the telefax. All telefaxes sent after 16h30 or on a day which is not a business day will be deemed to have been received on the following business day; and
          <br/>
          20.3.4. by email will be deemed to have been on the date indicated in the “Read Receipt” notification. ALL EMAIL COMMUNICATIONS BETWEEN YOU AND US MUST MAKE USE OF THE “READ RECEIPT” FUNCTION to serve as proof that an email has been received.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Complaints
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          21.1. We are a participant under the Consumer Goods and Services Industry Code and are bound by it. An electronic copy of this Code is available at http://www.cgso.org.za/downloads/. If you have a complaint about the goods or services provided by us or require information regarding our internal complaints-handling process, please contact us via our email, shop@forestcreations.co.za or call us at: 0217037082
          <br/>
          21.2. If we do not resolve your complaint within 15 (fifteen) business days of you having notified us of it, you are entitled to approach the Consumer Goods and Services Ombud (“CGSO”), to assist in resolving the dispute. The CGSO’s contact details are Website: http://www.cgso.org.za/ Sharecall: 0860 000 272 Email: complaints@cgso.org.za
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
