import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import flooring from '../../assets/customs/flooring.jpg';
import FloorModal from './FloorModal';

export default function ImgMediaCard() {
  return (
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="green iguana"
        height="250px"
        image={flooring}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Flooring
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Cover your home with our gorgeous extra wide and long tongue & groove flooring. Our go-to pick for flooring is Pinus Pinea (stone-pine). It is harder and more durable than other pine trees, and its beauty matches its durability. All our recent stone-pine comes from a recent fire on the Table Mountain National Park.
        <br/> <br/>   
        We also have a range of gorgeous Eucalyptus (gum), trees. Due to the tongue & groove that we put onto the flooring, as well as it being longer and wider, our flooring is extremely easy to install.
        <br/> <br/>           
        We offer other wood-types on request.
        </Typography>
      </CardContent>
      <CardActions>
        <Button  size="small" sx={{color:'black', marginTop:5}}><FloorModal /></Button>
        <Button  size="small" sx={{color:'black', marginTop:5}} href='mailto:shop@forestcreations.co.za' target="_blank">Get in Contact</Button>
      </CardActions>
    </Card>
  );
}
