import React, {useEffect, useState} from 'react';
import commerce from '../../../lib/commerce';
import Grid from '@material-ui/core/Grid';
import Product from '../Product/Product';
import useStyles from '../Product/styles';
import { Container } from '@material-ui/core';
import { CircularProgress } from '@mui/material';



const Bowls = ({ onAddToCart, categories}) => {
    const classes = useStyles();
  const [products, setProducts] = useState([]);

 

  useEffect(() => {
    commerce.products.list({
      category_slug:['CHAIRS-STOOLS'],
      sortBy: 'price'
    }).then(response => {
      setProducts(response.data)
    }).catch(err => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (!products.length) return <div className={classes.spinner}>
    <CircularProgress style={{color:'rgb(9, 78, 38) '}}/>
  </div>;

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Container style={{marginTop:'100px'}}>
      <Grid container justifyContent="center" spacing={4} style={{marginTop:'15px'}}>
      { products && products.map((product) => (
          <Grid item key={product.id}  xs={12} sm={6} md={4} lg={3}>
            <Product product={product} onAddToCart={onAddToCart}/>
          </Grid>
        ))}
      </Grid>
      </Container>
    </main>
  )
}

export default Bowls