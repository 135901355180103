import { Button,  Divider, Typography } from '@material-ui/core'
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './Checkout/styles';



const OrderComplete = (order) => {
    const classes = useStyles();

  return (
    
        <>
          <div style={{marginTop:'150px'}}>
            <Typography variant="h5">Thank you for your purchase, !</Typography>
            <Divider className={classes.divider} />
            <Typography variant="subtitle2">Order ref: </Typography>
          </div>
          <br />
          <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
        </>
      ) 
    
    //    (error) {
    //     Confirmation = () => (
    //       <>
    //         <Typography variant="h5">Error: {error}</Typography>
    //         <br />
    //         <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
    //       </>
    //     );
    //   }
  
}

export default OrderComplete