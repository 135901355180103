import React from 'react';
import { Typography, Button, Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import useStyles from './styles';

const CartItem = ({ item, onUpdateCartQty, onRemoveFromCart }) => {
  const classes = useStyles();
  console.log(item)

//   const handleUpdateCartQty = (lineItemId, newQuantity) => onUpdateCartQty(lineItemId, newQuantity);

//   const handleRemoveFromCart = (lineItemId) => onRemoveFromCart(lineItemId);

  return (
    <Card className="cart-item">
       <CardMedia className={classes.media} title={item.name} > 
      <img className={classes.media} src={item.image?.url} alt={item.name} />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5">{item.name}</Typography>
        <Typography variant="h6" style={{marginLeft:'5px'}}>{item.line_total.formatted_with_symbol}</Typography>
      </CardContent>
      <CardContent>
      {/* <Typography variant="body2" component="p" className={classes.cardInventory}>
            In Stock:{item.invetory_managed}
          </Typography> */}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.buttons}>
          <Button type="button" size="small" onClick={() => onUpdateCartQty(item.id, item.quantity - 1)}>-</Button>
          <Typography>&nbsp;{item.quantity}&nbsp;</Typography>
        </div>
        <Button variant="contained" type="button" style={{backgroundColor:'green', color:'white', marginLeft:'auto'}} onClick={() => onRemoveFromCart(item.id)}>Remove</Button>
      </CardActions>
    </Card>
  );
};

export default CartItem;
