import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import decking from '../../assets/customs/decking.JPG';
import DeckingModal from './DeckingModal'

export default function ImgMediaCard() {
  return (
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="green iguana"
        height="250px"
        image={decking}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Decking
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Try and beat our competitive decking pricing! We use several types of Eucalyptus (gum), due to the nature of this species. Gum is an exceptionally durable wood and can live a life of longevity outdoors. It is also a brilliant replacement for the much used and eco-sensitive Balau.
        <br/> <br/>   
        This is the outdoor touch that your home has been needing!
        <br/> <br/>           
        We have a variety of grades going at different rates.
        </Typography>
      </CardContent>
      <CardActions>
      <Button  size="small" sx={{color:'black', marginTop:5, }}><DeckingModal /></Button>
        <Button  size="small" sx={{color:'black', marginTop:5}} href='mailto:shop@forestcreations.co.za' target="_blank">Get in Contact</Button>
      </CardActions>
    </Card>
  );
}
