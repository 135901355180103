import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: 'rgb(235, 235, 235)',
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  spinner:{
    marginTop: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
