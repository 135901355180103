import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  height: '70%',
  overflowY: 'auto'
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{color:'black', fontSize:'10px', fontWeight:'400',}}>Cookie's</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            By using this site you agree to Forest Creations Cookie policy
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Please read this cookie policy (“cookie policy”, "policy") carefully before using [forestcreations.co.za] website
          (“website”, "service") operated by [Forest Creations] ("us", 'we", "our"). 
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            What are cookies?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Cookies are simple text files that are stored on your computer or mobile device by a website’s
          server. Each cookie is unique to your web browser. It will contain some anonymous information
          such as a unique identifier, website’s domain name, and some digits and numbers.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            What types of Cookies do we use?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Necessary cookies
          <br/><br/>
          Necessary cookies allow us to offer you the best possible experience when accessing and
          navigating through our website and using its features. For example, these cookies let us
          recognize that you have created an account and have logged into that account.
          <br/><br/>
          Functionality cookies
          <br/><br/>
          Functionality cookies let us operate the site in accordance with the choices you make. For
          example, we will recognize your username and remember how you customized the site during
          future visits.
         <br/><br/>
          Analytical cookies
          <br/><br/>
          These cookies enable us and third-party services to collect aggregated data for statistical
          purposes on how our visitors use the website. These cookies do not contain personal
          information such as names and email addresses and are used to help us improve your user
          experience of the website. 
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How to delete cookies?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. Alternatively, you can visit www.internetcookies.org, which contains comprehensive information on how to do this on a wide variety of browsers and devices. You will find general information about cookies and details on how to delete cookies from your device. 
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contacting Us
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          If you have any questions about this policy or our use of cookies, please contact us. 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
