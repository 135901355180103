import React from 'react';
import { Typography, Button, Divider } from '@material-ui/core';
import Review from './Review';
import { PaystackButton } from "react-paystack";
import useStyles from './Checkout/styles';


const PaymentFormPaystack = ({ checkoutToken, backStep, shippingData, onCaptureCheckout, nextStep }) => {

const handleSubmit = async (event, ) => { 
  event.preventDefault();


  const orderData = {
  line_items: checkoutToken.live.line_items,
  customer: { firstname: shippingData.firstName, lastname: shippingData.lastName, email: shippingData.email },
  shipping: { name: 'International', street: shippingData.address1, town_city: shippingData.city, county_state: shippingData.shippingSubdivision, postal_zip_code: shippingData.zip, country: shippingData.shippingCountry },
  fulfillment: { shipping_method: shippingData.shippingOption },
  billing: {name: 'International', street: shippingData.address1, town_city: shippingData.city, county_state: shippingData.shippingSubdivision, postal_zip_code: shippingData.zip, country: shippingData.shippingCountry },
  payment: {
    gateway: 'paystack',
    paystack: {
      reference: (new Date()).getTime().toString(),
    },
  },
};

onCaptureCheckout(checkoutToken.id, orderData);
// nextStep();
}

// const publicKey = process.env.REACT_APP_PAYFAST_PUBLIC_KEY

const config = {
  reference: (new Date()).getTime().toString(),
  email: shippingData.email,
  amount: checkoutToken.live.subtotal.raw * 100,
  currency: 'ZAR',
  // metadata: {
  //   name: shippingData.firstName,
  //   items: checkoutToken.live.products
  // },
  publicKey: 'pk_test_c624c5b327cf93b3a40e6849ca88e173ba8af1c8',
};

// pk_live_cdbf5a35b778eb5faafde14079b92cf1be583fed - forest creations live
// pk_test_4011f1daa2d6a7a13e0d57f14dbe19ad0c55878e - aqua dev test

  const componentProps = {
    ...config,
   
    text: "Pay Now",
    onSuccess: () => nextStep(),
    onClose: () => alert("Are you sure?🤔"),
  }

  const classes = useStyles();

  return (
    <>
      <Review checkoutToken={checkoutToken}/>
      <Divider />
      <Typography variant="h6" gutterBottom style={{ margin: '20px 0' }}>
       Payment method
      </Typography>
      <div className="checkout-form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
          <Button variant="outlined" onClick={backStep}>Back</Button>
          <PaystackButton type="submit" className={classes.pay} {...componentProps} />
          {console.log(PaystackButton)}
          </div>
          </form>
         
        </div>
    </>
  )
}

export default PaymentFormPaystack