import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  media: {
    height: '250px',
    width: '100%',
    objectFit: 'contain',
    marginTop: '-3px',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardInventory: {
    marginTop:'-20px'
  },
  cartActions: {
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
}));
