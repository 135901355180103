import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Disclaimermodal from './Discalimermodal';
import Disclaimer from './Disclaimer';
import Cookiepolicy from './Cookiepolicy';
import { IconButton, List } from '@material-ui/core';
import { Email, Facebook, Instagram, LinkedIn, LocationOn, WhatsApp } from '@material-ui/icons';
import './Footer.css';




function Copyright() {

  return (
    <Typography variant="body2" color="text.primary" style={{fontWeight:'400'}}>
      {'Copyright © '}
      <Link color="inherit" href="https://forestcreations.co.za" target='_blank'>
        Forest Creations
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'auto',
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          backgroundImage: `linear-gradient(to bottom, rgba(235, 235, 235, 1), rgba(9, 78, 38, 1))`,
        }}
      >
        <Container maxWidth="sm" className='footer'>
        <CssBaseline />
        <div className='footer__left'>
            <List >
                <Disclaimer />
                <Disclaimermodal />
                <Cookiepolicy />
            </List>
        </div>
        <div className='footer__center'>
        <Copyright />
        </div>
        <div className='footer__right'>
            <List>
                <IconButton className='facebook__icon' href='https://www.facebook.com/ForestCreationsTimberDesigns' target='_blank'>
                    <Facebook />
                </IconButton>
                <IconButton className='instagram__icon' href='https://www.instagram.com/forest.creations' target='_blank'>
                    <Instagram/>
                </IconButton>
                <IconButton className='linkedin__icon' href='https://www.linkedin.com/company/forest-creations-pty-ltd/' target='_blank'>
                    <LinkedIn/>
                </IconButton>
                <IconButton className='whatsapp__icon' href='tel:+27722145188' target='_blank'>
                    <WhatsApp />
                </IconButton>
                <IconButton className='email__icon' href='mailto:shop@forestcreations.co.za' target='_blank'>
                    <Email />
                </IconButton>
                <IconButton className='email__icon' href='https://www.google.com/maps/place/Forest+Creations/@-34.004266,18.525422,16z/data=!4m5!3m4!1s0x0:0xb23b74bd7071c603!8m2!3d-34.0042658!4d18.5254223?hl=en-GB' target='_blank'>
                    <LocationOn />
                </IconButton>
            </List>
        </div>
        </Container>
      </Box>
    </Box>
  );
}