import React  from 'react';
import { AppBar, Toolbar, IconButton, Badge, Typography } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import logo2 from '../../assets/brand/logo2.png';
import useStyles from './styles';
import Sidenav from './Sidenav'


const Navbar = ({ totalItems }) => {
  const classes = useStyles();
  
  return (
    <nav>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar style={{justifyContent:'space-evenly',  backgroundImage: `linear-gradient(to bottom, rgba(9, 78, 38, 1), rgba(235, 235, 235, 1))`,}}> 
        <Link to="/" style={{textDecoration:'none', color:'black',}}>
          <Typography variant="h6" className={classes.title} color="inherit">
              <img src={logo2} alt="Forest Creations Logo" height="25px" className={classes.image} /> 
            </Typography>
          </Link>
            <Link to='/customorders' style={{textDecoration:'none', color:'inherit'}}>
            <Typography variant="body1" className={classes.title}>
              Customs Orders
              </Typography>
            </Link>
            <Link to='/about' style={{textDecoration:'none', color:'inherit'}}>
            <Typography variant="body1" className={classes.title}>
              About 
              </Typography>
            </Link>
            <Sidenav />
          {/* <div className={classes.grow} /> */}
          <div className={classes.button}>
          <Link to='/cart' style={{textDecoration:'none', color:'black'}}>
          <IconButton aria-label="Show cart items" color="inherit">
              <Badge badgeContent={totalItems} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Navbar;
