import React from 'react';
import { Container, CssBaseline, Grid, Typography } from '@material-ui/core';
import Floors from './Floors';
import Doors from './Doors';
import Decking from './Decking';
import Imagelist from './Imagelist'


const Customorders = () => {
  return (
    <Container>
    <CssBaseline />
    <Typography style={{marginTop:'130px',}} className="custom__header">
    <h2 style={{ display:'flex', alignItems:'center', justifyContent: 'center'}}>We specialize in ALL types of custom orders</h2>
    </Typography>
    <Grid container spacing={3}  style={{justifyContent:'center',}}>
    <CssBaseline />
      <Doors />
      <Floors />
      <Decking />
    </Grid>
    <Typography style={{marginTop:'40px',}}>
    <h3 style={{ display:'flex', alignItems:'center', justifyContent: 'center'}}>Or, use our wood for any of your own purposes</h3>
    </Typography>
    <CssBaseline />
    <Imagelist />
    <Container>
    <div className="emdeb-responsive">
    <iframe title='Forest Creations Location' style={{ height:'500px', width:'100%', border:"0", marginTop:'50px'}} loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJX4UsqIFEzB0RA8ZxcL10O7I&key=AIzaSyC_gUn0zmMhN-hmz7A0MsrdMpazIm3noUI"></iframe>
    </div>
    </Container>
    </Container>
  )
}


// src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJX4UsqIFEzB0RA8ZxcL10O7I&key=AIzaSyC_gUn0zmMhN-hmz7A0MsrdMpazIm3noUI"
export default Customorders