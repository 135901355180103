import React from 'react';
import Family from './Family';

const About = () => {
  return (
    <div>
    <Family />
    </div>
  )
}

export default About