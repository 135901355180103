import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{color:'black', fontSize:'10px', fontWeight:'400'}}>Disclaimer</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms of Use/Disclaimer
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Due to every tree being unique in terms of colouring, grain and shape, some products may differ from the photo displayed. Points of difference could be but are not limited to, colour, shape and grain of the wood. 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
