import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { CssBaseline } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import gg from '../../assets/about/gg.jpg';
import Grant from '../../assets/about/Grant.jpg';
import Craig from '../../assets/about/Craig.jpg';
import mom from '../../assets/about/mom.jpg';
import grandfather from '../../assets/about/grandfather.png';
import dad from '../../assets/about/dad.jpg';
import './FamilyHistory.css';

const Family = () => {
  return (
    <Container className='family__container'>
        <CssBaseline />
        <Typography style={{marginTop:'90px', display:'flex', alignItems:'center', 
        justifyContent:'center'}}>
            <h2>Our Story Starts Here</h2>
        </Typography>
        <div  style={{justifyContent:'center', display:'flex'}}>
        <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="Great Grandfather Bramwell"
        height="200px"
        image={gg}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Great Grandfather
        </Typography>
        <Typography variant="body2" color="text.secondary">
        I was a master carpenter, I passed down every ‘tool’ of my trade to the next generations! Wood-sculpting was a deep passion of mine that went on to keep inspiring the rest of the family.
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="Grandfather Bramwell"
        height="200px"
        image={grandfather}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Grandfather
        </Typography>
        <Typography variant="body2" color="text.secondary">
        I loved to do art, of any kind. This owl was one of my early pieces, it is now framed and hanging in Grant’s Elandsbaai house. I made metal furniture in my spare time. Good craftsmanship and new inventions drive me! 
        </Typography>
      </CardContent>
    </Card>
        </div>
        <div  style={{justifyContent:'center', display:'flex'}}>
        <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="Mother Bramwell"
        height="200px"
        image={mom}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Mother
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Art will always be embedded deep in my heart. Photography, sketching and painting. The boys (Grant and Craig) have art of mine hanging on their walls to this day! My creativity and eye for beauty in every part of our world is something that my boys learnt from me from an early age.
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="Father Bramwell"
        height="200px"
        image={dad}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Father
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Many have dubbed me as a ‘master wood craftsman.’ In my spare time, I made many a model boat. I have also made full-size boats. The boys took my passion of woodwork, and under my tutelage, put it into a lathe and shaped it into their future.
        </Typography>
      </CardContent>
    </Card>
        </div>
        <div  style={{justifyContent:'center', display:'flex'}}>
        <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3, marginBottom: 3,  }} className="custom__card">
      <CardMedia
        component="img"
        alt="Grant Bramwell"
        height="200px"
        image={Grant}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Grant Bramwell
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          Founder & Co-owner
        </Typography>
        <Typography variant="body2" color="text.secondary">
        I matriculated in 1984 with a distinction in woodwork, I then studied for a National Diploma in analytical chemistry worked for six years, after discovering it was not for me, I made surfboards for friends and family between 1980 and 1990 on weekends and enjoyed other hobbies like amateur electronics and jewelry manufacture. After stopping chemistry, Craig and I did leather work for two years, making boots, sandals, jackets, and lingerie. I then started Forest Creations in 1991, making small rustic picture frames. It is all grown from there.
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3, marginBottom: 3, }} className="custom__card">
      <CardMedia
        component="img"
        alt="Craig Bramwell"
        height="200px"
        image={Craig}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Craig Bramwell
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          Co-owner
        </Typography>
        <Typography variant="body2" color="text.secondary">
        I matriculated in 1986 with a distinction in woodwork, I then studied for a National Diploma in architecture. After spending three years travelling in Europe, I started working with Grant on rustic wooden furniture in my father’s garage. That was the beginning of Forest Creations. After a few years, I decided to leave my brother with the business and spent three years in America with my then-girlfriend. I returned as a father and became involved in the building industry by building an organic family home. After that, I spent three years building exclusive lodges at Van Staden’s in the Eastern Cape. When I returned to Cape Town, Grant and I joined forces with our building and carpentry skills. In 2006, we bought industrial land and turned Forest Creations into a fully functional sawmill and woodworking operation. 
        </Typography>
      </CardContent>
    </Card>
        </div>
    </Container>
  )
}

export default Family