import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import doors from '../../assets/customs/doors.gif';
import DoorPricing from './DoorPricing';

export default function ImgMediaCard() {
  return (
    <Card sx={{ maxWidth: 345, marginLeft: 3, marginTop: 3 }} className="custom__card">
      <CardMedia
        component="img"
        alt="green iguana"
        height="250px"
        image={doors}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Frameless Doors
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Enjoy your stunning views unhindered and frameless.
        <br/> <br/>   
        Our foldable, frame-less stack away doors are made with 10mm armor plated safety glass, making for an excellent sound and thermal insulator!
        <br/> <br/>           
        Custom made to any size. Standard wood used is Cypress or Meranti, other wood can be used on special request, it will, however, alter pricing.
        </Typography>
      </CardContent>
      <CardActions>
        <Button  size="small" sx={{color:'black', marginTop:5}} ><DoorPricing /></Button>
        <Button  size="small" sx={{color:'black', marginTop:5 }} href='mailto:shop@forestcreations.co.za' target="_blank">Get in Contact</Button>
      </CardActions>
    </Card>
  );
}
